import {
  formatTimezonesToOptions,
  getRelativeDates,
  relativeDateOptions
} from '@sparkpost/report-builder/helpers/date';
import { isAfter } from 'date-fns';

const TIMEZONE_OPTIONS = formatTimezonesToOptions();

const PRECISION_OPTIONS = ['hour', 'day'];

export function validateQueryParams({ from, to, range, timezone, precision }) {
  const fromTime = new Date(from);
  const toTime = new Date(to);
  let validParams = {};

  if (from && !isNaN(fromTime)) {
    validParams.from = fromTime;
  }

  if (to && !isNaN(toTime)) {
    validParams.to = toTime;
  }

  if (timezone && TIMEZONE_OPTIONS.find(({ value }) => value === timezone)) {
    validParams.timezone = timezone;
  }

  if (precision && PRECISION_OPTIONS.includes(precision)) {
    validParams.precision = precision;
  }

  if (range) {
    const invalidRange = !relativeDateOptions.map((dateObj) => dateObj.value).includes(range);
    const effectiveRange = invalidRange ? 'day' : range;
    validParams = { ...validParams, ...getRelativeDates.useMomentInput(effectiveRange) };
  }

  if ((!from || !to) && !range)
    validParams = { ...validParams, ...getRelativeDates.useMomentInput('7days') };

  if (isAfter(new Date(from), new Date(to))) {
    validParams = { ...validParams, from: validParams.to, to: validParams.from };
  }

  return validParams;
}

export function getMetrics({ checkboxes, hasD12yProduct, hasSendingProduct, type }) {
  let metrics = [];
  let selectedCheckboxes = checkboxes.filter((x) => x.isChecked).map((x) => x.name);

  if (selectedCheckboxes.length === 0 && hasD12yProduct && hasSendingProduct) {
    if (type !== 'subject-campaign') {
      selectedCheckboxes = ['sending', 'panel', 'seed'];
      metrics = [
        'count_unique_confirmed_opened',
        'count_unique_clicked',
        'count_spam_complaint',
        'count_unsubscribe',
        'count_accepted',
        'count_block_bounce',
        'count_nonprefetched_unique_confirmed_opened'
      ];
    } else {
      selectedCheckboxes = ['panel', 'seed'];
    }
  }

  if (selectedCheckboxes.length === 0 && !hasD12yProduct && hasSendingProduct) {
    selectedCheckboxes = ['sending'];
  }

  if (selectedCheckboxes.includes('sending')) {
    metrics = [...metrics, 'count_sent'];
  }

  if (selectedCheckboxes.includes('seed')) {
    metrics = [...metrics, 'count_inbox_seed', 'count_spam_seed'];
  }

  if (selectedCheckboxes.includes('panel')) {
    metrics = [...metrics, 'count_spam_panel', 'count_inbox_panel'];
  }

  return metrics;
}

export function areAllThisValue(array, value) {
  return array.every((val) => val === value) ? value : undefined;
}

export function isAnyThisValue(array, value) {
  return array.some((val) => val === value) ? value : undefined;
}
